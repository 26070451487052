1<template>
  <div>
    <v-container pa-0 fluid style="min-height: 25px">
      <v-row justify="end" class="font-weight-black">
        <v-spacer></v-spacer>
        <v-col cols="12" sm="2" style="text-align: end;">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn icon
                id="notas-debito-botao-exportar"
                class="mx-0"
                v-on="on"
                @click="requestExportacao">
                <v-icon>get_app</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('label.exportar_conteudo') }}</span>
          </v-tooltip>
          <v-badge
              bordered
              color="orange"
              icon="mdi-lock"
              overlap
              v-show="!abrirFiltro"
              v-if="habilitarIconBadge">
              <v-icon v-show="!abrirFiltro" @click="abrirFiltro = !abrirFiltro">filter_list</v-icon>
          </v-badge>
          <v-icon v-else v-show="!abrirFiltro" @click="abrirFiltro = !abrirFiltro">filter_list</v-icon>
        </v-col>
      </v-row>
    </v-container>

    <notas-debito-consulta-filtros
      ref="filtros"
      v-model="abrirFiltro"
      @NotasDebitoConsultaFiltros__AplicaFiltros="aplicarFiltros"
      @FiltroRapido__HabilitaIconBadge="habilitaIconBadge"/>

    <notas-debito-consulta-tabela
      ref="tabela"/>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import NotasDebitoConsultaFiltros from './NotasDebitoConsultaFiltros';
import NotasDebitoConsultaTabela from './NotasDebitoConsultaTabela';
import exportacao from '../../../common/functions/exportacao';

export default {
  components: {
    NotasDebitoConsultaFiltros,
    NotasDebitoConsultaTabela,
  },
  computed: {
    ...mapGetters('metadados', [
      'getAcaoMetadado',
    ]),
  },
  data() {
    return {
      abrirFiltro: false,
      filtros: Object,
      habilitarIconBadge: false,
    };
  },
  methods: {
    ...mapActions('metadados', [
      'setAcaoMetadado',
    ]),
    aplicarFiltros(filtros) {
      this.$refs.tabela.filtrar(filtros);
      this.filtros = filtros;
    },
    habilitaIconBadge(valor) {
      this.habilitarIconBadge = valor;
    },
    requestExportacao() {
      const params = {};
      if (this.filtros.origem && this.filtros.origem.length === 1) {
        if (this.filtros.origem[0] === 'CONTRATO') {
          this.filtros.contrato = 'CONTRATO';
        } else {
          this.filtros.acao = 'ACAO';
        }
      }
      Object.keys(this.filtros).forEach((key) => {
        const value = this.filtros[key];
        if ((Array.isArray(value) && value.length)
           || (!Array.isArray(value) && value !== undefined && value !== null)) {
          params[key] = value;
        }
      });

      exportacao.exportar(null, 'notas_debito_magalu', this, params, 5000, 0);
    },
  },
};
</script>
