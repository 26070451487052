export default {
  data() {
    return {
      notaDebitoResource: this.$api.notasDebitoConsulta(this.$resource),
      tipoAcaoResource: this.$api.tipoAcao(this.$resource),
      divisaoResource: this.$api.divisao(this.$resource),
      planejamentoAcaoResource: this.$api.planejamentoAcaoCadastro(this.$resource),

      camposFormulario: {
        padrao: [
          {
            labelCampo: 'cod_acao',
            tipoCampo: 'NUMERICO',
          },
          {
            labelCampo: 'cod_apuracao',
            tipoCampo: 'NUMERICO',
          },
          {
            labelCampo: 'numero',
            tipoCampo: 'NUMERICO',
          },
          {
            labelCampo: 'grupo_fornecedor',
            nomCampoId: 'id_grupo_fornecedor',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            vlrObrigatorio: true,
            async: {
              fetchFunction: (autocomplete) => this.planejamentoAcaoResource.buscarGruposFornecedores({ autocomplete }),
              itemValue: 'id',
              itemText: 'codNome',
            },
          },
          {
            labelCampo: 'status_nd',
            nomCampoId: 'status_entidade',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            async: {
              fetchFunction: () => new Promise((resolve) => resolve({
                data: [
                  {
                    texto: this.$t('status_entidade.aguardando_aprovacao'),
                    valor: 'AGUARDANDO_APROVACAO',
                  },
                  {
                    texto: this.$tc('status_entidade.em_cadastro', 1),
                    valor: 'EM_CADASTRO',
                  },
                  {
                    texto: this.$tc('status_entidade.aprovado', 1),
                    valor: 'APROVADO',
                  },
                  {
                    texto: this.$tc('status_entidade.reprovado', 1),
                    valor: 'REPROVADO',
                  },
                  {
                    texto: this.$tc('status_entidade.cancelado', 1),
                    valor: 'CANCELADO',
                  },
                  {
                    texto: this.$tc('status_entidade.em_analise', 1),
                    valor: 'EM_ANALISE',
                  },
                ],
              })),
              itemValue: 'valor',
              itemText: 'texto',
            },
          },
          {
            labelCampo: 'status_integracao',
            nomCampoId: 'status',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            async: {
              fetchFunction: () => new Promise((resolve) => resolve({
                data: [
                  { texto: `${this.$tc('status_integracao.ERRO', 1)}`, valor: 'ERRO' },
                  { texto: `${this.$tc('status_integracao.INTEGRADO', 1)}`, valor: 'INTEGRADO' },
                  { texto: `${this.$tc('status_integracao.PENDENTE_INTEGRACAO', 1)}`, valor: 'PENDENTE_INTEGRACAO' },
                ],
              })),
              itemValue: 'valor',
              itemText: 'texto',
            },
          },
          {
            labelCampo: 'cod_fornecedor_acao',
            tipoCampo: 'TEXTO',
          },
          {
            labelCampo: 'data_inicio_venc',
            tipoCampo: 'DATA',
          },
          {
            labelCampo: 'data_fim_venc',
            tipoCampo: 'DATA',
          },
          {
            labelCampo: 'data_inicio_cont',
            tipoCampo: 'DATA',
          },
          {
            labelCampo: 'data_fim_cont',
            tipoCampo: 'DATA',
          },
          {
            labelCampo: 'tipo_acao',
            nomCampoId: 'id_acao_tipo',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            async: {
              fetchFunction: (autocomplete) => this.tipoAcaoResource.buscarTodosTiposAcao({ autocomplete }),
              itemValue: 'id',
              itemText: 'nome',
            },
          },
          {
            labelCampo: 'divisao',
            nomCampoId: 'id_divisao',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            async: {
              fetchFunction: () => this.notaDebitoResource
                .buscaDivisoes(),
              itemValue: 'id',
              itemText: 'nome',
            },
          },
          {
            labelCampo: 'origem',
            nomCampoId: 'origem',
            tipoCampo: 'LISTA',
            async: {
              fetchFunction: () => new Promise((resolve) => resolve({
                data: [
                  { texto: `${this.$tc('label.contrato', 1)}`, valor: 'CONTRATO' },
                  { texto: `${this.$tc('label.acao_tatica', 1)}`, valor: 'ACAO' },
                ],
              })),
              itemValue: 'valor',
              itemText: 'texto',
            },
          },
          {
            labelCampo: 'data_inicio_apu',
            tipoCampo: 'DATA',
          },
          {
            labelCampo: 'data_fim_apu',
            tipoCampo: 'DATA',
          },
          {
            labelCampo: 'ocultar_nds_zeradas',
            tipoCampo: 'BOOLEAN',
          },
          {
            labelCampo: 'unidadenegocio',
            nomCampoId: 'id_unidadenegocio',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            async: {
              fetchFunction: (autocomplete) => this.planejamentoAcaoResource
                .buscarUnidadesNegocioEntidadesControlador({ autocomplete }),
              itemValue: 'id',
              itemText: 'nomExtensao',
            },
          },
        ],
      },
    };
  },
};
